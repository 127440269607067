import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';
import { SiteKey, cfSiteKey } from "../../../utils/settings.js";
//import { SiteKey } from '../../../../vue.config.js';

export default {
  state() {
    return {
      authStatus: '',
      accessToken: '',
      tokenExp: localStorage.getItem('tokenExp') || '',
      refreshExp: localStorage.getItem('refreshExp') || '',
      user: JSON.parse(localStorage.getItem('user')) ||{
        id: '',
        username: '',
        name: '',
        custno: '',
        company: '',
        is_manager: '',
        pay_type: '',
        suspended: '',
        sales: '',
        groups: [],
        eblast: false
      },
      reCaptchaSiteKey: SiteKey,
      cfSiteKey: cfSiteKey,
    };
  },
  mutations,
  actions,
  getters
};


