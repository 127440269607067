<template>
  <nav class="navbar navbar-expand-lg navbar-light na">
    <div class="container" style="max-width: 1920px !important; margin: 0 auto;">
      <router-link :to="{ name: 'Home' }" class="navbar-brand"><img src="@/assets/images/malabs-logo.png"/></router-link>
      <button id="btn_1" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_menu" aria-controls="main_menu" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="main_menu">
        <ul class="navbar-nav mb-2 mb-lg-0">
          
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" aria-expanded="false" v-on:click.prevent.stop="handleProductnav('toggle')">Products</a>
          </li>
          
          <div id="navproducts" name="subnav">

            <!-- product list in larger window size -->

            <div id ="product_links">
              <!-- category level 1: top category-->
              <div class="links">
                <ol class="list-group">
                  <li name="cate1" class="list-group-item d-flex align-items-start" 
                    v-for="(cate, index) in category1_list" 
                    @mouseover="showCategoryLevel2(cate, index)" 
                    :id="'cate1_' + index" >
                    <div class="ms-2 me-auto">
                      <router-link :to="'/products/?catid=' + cate.catid +'&ordering=-item__balance'">{{cate.categoryname}}</router-link>
                    </div>
                    <span v-if="cate && cate.sub_categories.length" class="badge"><i class="arrow arrow_right"></i></span>
                    
                    <!-- category level 2 -->
                    <div v-if="cate.sub_categories.length" class="links level2">
                      <div id="pointer"></div>
                      <ol class="list-group">
                        <li name="cate2" class="list-group-item d-flex align-items-start"
                          v-for="(ct, index) in cate.sub_categories" 
                          :id="'cate2_' + index">
                          <router-link name="child" :to="'/products/?catid=' + ct.catid+'&ordering=-item__balance'">{{ct.categoryname}}</router-link>
                        </li> 
                      </ol> 
                    </div>

                  </li>
                </ol>
              </div>
              

            </div>

            <!-- product list in smaller window size -->
            <div id ="product_links_sm">
              
              <!-- category level 1 (smaller window size) -->
              <div class="links_sm" id="sm_cate1">
                <ol class="list-group">
                  <li class="list-group-item d-flex align-items-start" 
                    v-for="(cate, index) in category1_list" 
                    :id="'sm_cate1_' + index" >
                    <div class="ms-2 me-auto">
                      <router-link :to="'/products/?catid=' + cate.catid +'&ordering=-item__balance'" v-on:click="hideSubnav()">{{cate.categoryname}}</router-link>
                    </div>
                    <span v-if="cate && cate.sub_categories.length" class="badge clickable" 
                      @mouseover="styleArrowLevel2Sm(index, 'mouseover')" 
                      @mouseout="styleArrowLevel2Sm(index, 'mouseout')"
                      @click.stop="showCategoryLevel2Sm(cate, index)" >
                      <i :id="'sm_cate1_arrow_' + index" name="sm_cate1_arrow" class="arrow arrow_right"></i>
                    </span>
                  </li>
                </ol>
              </div>

              <!-- category level 2 (smaller window size) -->
              <div class="links_sm" id="sm_cate2">
                <div class="subcate_top">
                      <div class="subcate_back">
                        <a @click.stop="handleGoBackToCategory(2, 1)">
                          <i class="arrow arrow_left"></i> Back to Main
                        </a>
                      </div>
                      <div class="subcate_name"></div>
                </div>   
                
                <ol class="list-group">
                  <li class="list-group-item d-flex align-items-start" 
                    v-for="(cate, index) in category2_list" 
                    :id="'sm_cate2_' + index">
                    <div class="ms-2 me-auto">
                       <router-link :to="'/products/?catid=' + cate.catid" v-on:click="hideSubnav()">{{cate.categoryname}}</router-link>
                    </div>                    
                  </li> 
                </ol> 
              </div>

            </div>

          </div>


          
          <!-- <li class="nav-item">
             <router-link class="nav-link" v-on:click="hideSubnav()" :to="{name: 'ServerSolutions'}">Solutions</router-link>
          </li> -->
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false" v-on:click.stop="showSubnav('solution')">Solutions</a>
            <div id="navsolution" class="subnav" name="subnav">
              <div class="list-group"> 
                <!-- large screen -->
                <div class="subnav_lg">
                <router-link class="list-group-item list-group-item-action d-flex align-items-center" 
                  name="subnav_item" v-on:click="hideSubnav()" v-on:mouseenter="showSubList('server')" v-on:mouseleave="hideSubList('server')" :to="{name: 'ServerSolutions'}">
                  <i class="bi bi-server icon"></i>
                  <span class="w-100">Server Solutions</span>
                  <span class="badge" >
                    <i name="sm_cate1_arrow" class="arrow arrow_right"></i>
                  </span>
                  <div id="sublist_server" class="submenu w-100">
                    <div class="arrow_pointer"></div>
                    <ul class="list-group">
                      <li><router-link :to="{name: 'ServerSolutions'}">Server Solutions Homepage</router-link></li>
                      <li><router-link :to="{ name: 'GpuServer' }">GPU Servers</router-link></li>
                      <li><router-link :to="{ name: 'StorageServer' }">Storage Servers</router-link></li>
                    </ul>
                  </div>  
                </router-link>
                </div>
                <!-- small screen -->
                <div class="subnav_sm">
                <router-link class="list-group-item list-group-item-action d-flex align-items-center" 
                  name="subnav_item" v-on:click.stop="toggleSecSubList('server_sm')" :to="{name: 'ServerSolutions'}">
                  <i class="bi bi-server icon"></i>
                  <span class="w-100">Server Solutions</span>
                  <span class="badge" >
                    <i name="sm_cate1_arrow" class="arrow arrow_right"></i>
                  </span>
                </router-link>
                <div id="sublist_server_sm" class="submenu_sm w-100" style="display: none;">
                    <ul class="list-group">
                      <li><router-link :to="{name: 'ServerSolutions'}" v-on:click="hideSubnav()">Server Solutions Homepage</router-link></li>
                      <li><router-link :to="{ name: 'GpuServer' }" v-on:click="hideSubnav()">GPU Servers</router-link></li>
                      <li><router-link :to="{ name: 'StorageServer' }" v-on:click="hideSubnav()">Storage Servers</router-link></li>
                    </ul>
                  </div> 
                </div>
                
                <router-link class="list-group-item list-group-item-action d-flex align-items-center" 
                  name="subnav_item" v-on:click="hideSubnav()" :to="{name: 'IndustrialSolutions'}">
                  <i class="bi bi-motherboard-fill icon"></i>
                  <span>Industrial Solutions</span>
                </router-link>
                <router-link class="list-group-item list-group-item-action d-flex align-items-center" 
                  name="subnav_item" v-on:click="hideSubnav()" :to="{name: 'GamingSolutions'}">
                  <i class="bi bi-controller icon"></i>
                  <span>Gaming Solutions</span>
                </router-link>
                <router-link class="list-group-item list-group-item-action d-flex align-items-center" 
                  name="subnav_item" v-on:click="hideSubnav()" :to="{name: 'EdgeSolutions'}">
                  <i class="bi bi-router-fill icon"></i>
                  <span>Edge Solutions</span>
                </router-link>
                <router-link class="list-group-item list-group-item-action d-flex align-items-center" 
                  name="subnav_item" v-on:click="hideSubnav()" :to="{name: 'DigitalSolutions'}">
                  <i class="bi bi-tv-fill icon"></i>
                  <span>Digital Signage Solutions</span>
                </router-link>
              </div>
            </div>
          </li>

          <!-- <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle " href="#" data-bs-toggle="dropdown" aria-expanded="false" v-on:click.stop="showSubnav('config')">Configuration</a>
            <div id="navconfig" class="subnav" name="subnav">
              <div class="list-group">                     
                 <router-link class="list-group-item list-group-item-action d-flex align-items-center" name="subnav_item" v-on:click="hideSubnav()" to="/configuration/server">
                  <i class="bi bi-server icon"></i>
                  <span>Server Configuration</span>
                 </router-link>
                <router-link class="list-group-item list-group-item-action d-flex align-items-center" name="subnav_item" v-on:click="hideSubnav()" to="/configuration/desktop">
                  <i class="bi bi-laptop-fill icon"></i>
                  <span>Desktop Configuration</span>
                </router-link>
              </div>
            </div>
          </li>  -->


          <!-- <li class="nav-item">
             <router-link class="nav-link" v-on:click="hideSubnav()" to="/partners/featured-stores">Partners</router-link>
          </li> -->
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false" v-on:click.stop="showSubnav('partners')">Partners</a>
            <div id="navpartners" class="subnav" name="subnav">
              <div class="list-group">         
                <router-link class="list-group-item list-group-item-action d-flex align-items-center" name="subnav_item" v-on:click="hideSubnav()" :to="{name: 'FeaturedStores'}">
                  <i class="bi bi-people-fill icon"></i>
                  <span>Featured Partners</span>
                </router-link>
                <router-link class="list-group-item list-group-item-action d-flex align-items-center" name="subnav_item" v-on:click="hideSubnav()" :to="{name: 'Partners'}">
                  <i class="bi bi-bag-heart-fill icon"></i>
                  <span>Featured Brands</span>
                </router-link>
                <router-link class="list-group-item list-group-item-action d-flex align-items-center" name="subnav_item" v-on:click="hideSubnav()" :to="{name: 'LineCard'}">
                  <i class="bi bi-file-earmark-text icon"></i>
                  <span>Product Line Card</span>
                </router-link>
                <router-link class="list-group-item list-group-item-action d-flex align-items-center" name="subnav_item" v-on:click="hideSubnav()" :to="{name: 'Webinars'}">
                  <i class="bi bi-play-btn-fill icon"></i>
                  <span>Ma Labs On-Demand Webinars</span>
                </router-link>
              </div>
            </div>
          </li>


          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false" v-on:click.stop="showSubnav('company')">Company</a>
            <div id="navcompany" class="subnav" name="subnav">
              <div class="list-group">
                <!-- <router-link name="subnav_item" :to="{ name: 'About'}" class="list-group-item list-group-item-action d-flex align-items-center"
                  v-on:click="hideSubnav()">
                  <img class="img_grey"  src="@/assets/images/home/company/Overview-Icon.png">
                  <img class="img_blue"  src="@/assets/images/home/company/Overview-Icon_blue.png">
                  <span>Overview</span>
                </router-link>
                <router-link name="subnav_item" :to="{ name: 'RecognitionsAndSpotlights'}" class="list-group-item list-group-item-action d-flex align-items-center"
                  v-on:click="hideSubnav()">
                  <img class="img_grey" src="@/assets/images/home/company/Awards-Icon.png">
                  <img class="img_blue" src="@/assets/images/home/company/Awards-Icon_blue.png">
                  <span>Recognitions & Spotlights</span>
                </router-link>
                <router-link name="subnav_item" :to="{ name: 'Events' }" class="list-group-item list-group-item-action d-flex align-items-center"
                  v-on:click="hideSubnav()">
                  <img class="img_grey" src="@/assets/images/home/company/Events-Icon.png">
                  <img class="img_blue" src="@/assets/images/home/company/Events-Icon_blue.png">
                  <span>Events</span>
                </router-link>
                <router-link name="subnav_item" :to="{ name: 'Careers'}" class="list-group-item list-group-item-action d-flex align-items-center"
                  v-on:click="hideSubnav()">
                  <img class="img_grey" src="@/assets/images/home/company/Careers-Icon.png">
                  <img class="img_blue" src="@/assets/images/home/company/Careers-Icon_blue.png">
                  <span>Careers</span>
                </router-link>
                <router-link name="subnav_item" :to="{ name: 'EmailNewsletters'}" class="list-group-item list-group-item-action d-flex align-items-center"
                  v-on:click="hideSubnav()">
                  <img class="img_grey" src="@/assets/images/home/company/Subscribe-Email-Icon.png">
                  <img class="img_blue" src="@/assets/images/home/company/Subscribe-Email-Icon_blue.png">
                  <span>Email Newsletters</span>
                </router-link> 
                <router-link name="subnav_item" :to="{ name: 'Branches'}" class="list-group-item list-group-item-action d-flex align-items-center"
                  v-on:click="hideSubnav()">
                  <img class="img_grey" src="@/assets/images/home/company/branch-Icon_.png">
                  <img class="img_blue" src="@/assets/images/home/company/branch-Icon_blue.png">
                  <span>Our Branches</span>
                </router-link>-->
                <router-link class="list-group-item list-group-item-action d-flex align-items-center" name="subnav_item" v-on:click="hideSubnav()" :to="{name: 'About'}">
                  <i class="bi bi-card-heading icon"></i>
                  <span>Overview</span>
                </router-link>
                <router-link class="list-group-item list-group-item-action d-flex align-items-center" name="subnav_item" v-on:click="hideSubnav()" :to="{name: 'RecognitionsAndSpotlights'}">
                  <i class="bi bi-award-fill icon"></i>
                  <span>Recognitions & Spotlights</span>
                </router-link>
                <router-link class="list-group-item list-group-item-action d-flex align-items-center" name="subnav_item" v-on:click="hideSubnav()" :to="{name: 'Events'}">
                  <i class="bi bi-calendar3 icon"></i>
                  <span>Events</span>
                </router-link>
                <router-link class="list-group-item list-group-item-action d-flex align-items-center" name="subnav_item" v-on:click="hideSubnav()" :to="{name: 'Careers'}">
                  <i class="bi bi-person-workspace icon"></i>
                  <span>Careers</span>
                </router-link>
                <router-link class="list-group-item list-group-item-action d-flex align-items-center" name="subnav_item" v-on:click="hideSubnav()" :to="{name: 'EmailNewsletters'}">
                  <!-- <i class="bi bi-megaphone-fill icon"></i> -->
                  <i class="bi bi-envelope-fill icon"></i>
                  <span>Email Newsletters</span>
                </router-link>
                <router-link class="list-group-item list-group-item-action d-flex align-items-center" v-on:click="hideSubnav()" :to="{name: 'Branches'}">
                  <i class="bi bi-globe icon"></i>
                  <span>Our Branches</span>
                </router-link>
              </div>

            </div>
          </li>


          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle " href="#" data-bs-toggle="dropdown" aria-expanded="false" v-on:click.stop="showSubnav('support')">Support</a>
            <div id="navsupport" class="subnav" name="subnav">
              <div class="list-group">         
                <!-- <router-link name="subnav_item" :to="{ name: 'ContactUs', params: { topic: '-' } }" class="list-group-item list-group-item-action d-flex align-items-center" 
                  v-on:click="hideSubnav()">
                  <img class="img_grey" src="@/assets/images/home/Contact-us_icon_.png">
                  <img class="img_blue" src="@/assets/images/home/Contact-us_icon_blue.png">
                  <span>Contact Us</span>
                </router-link>
                <router-link name="subnav_item" :to="{ name: 'RMARequest' }"  class="list-group-item list-group-item-action d-flex align-items-center"
                  v-on:click="hideSubnav()">
                  <img class="img_grey" src="@/assets/images/home/RMA-icon_.png">
                  <img class="img_blue" src="@/assets/images/home/RMA-icon_blue.png">
                  <span>RMA Request</span>
                </router-link> 
                <router-link name="subnav_item" :to="{ name: 'FAQ' }"  class="list-group-item list-group-item-action d-flex align-items-center"
                  v-on:click="hideSubnav()">
                  <img class="img_grey" src="@/assets/images/home/FAQ-icon_.png">
                  <img class="img_blue" src="@/assets/images/home/FAQ-icon_blue.png">
                  <span>FAQ</span>
                </router-link>  -->
                
                 <router-link class="list-group-item list-group-item-action d-flex align-items-center" name="subnav_item" v-on:click="hideSubnav()" to="/contact-us/-">
                  <i class="bi bi-people-fill icon"></i>
                  <span>Contact Us</span>
                 </router-link>
                <router-link class="list-group-item list-group-item-action d-flex align-items-center" name="subnav_item" v-on:click="hideSubnav()" :to="{name: 'RMARequest'}">
                  <i class="bi bi-megaphone-fill icon"></i>
                  <span>RMA Request</span>
                </router-link>
                <router-link class="list-group-item list-group-item-action d-flex align-items-center" name="subnav_item" v-on:click="hideSubnav()" :to="{name: 'FAQ'}">
                  <i class="bi bi-question-circle-fill icon"></i>
                  <span>FAQ</span>
                </router-link> 
              </div>
            </div>
          </li>

          
          
        </ul>

        <div id="search_form" class="ms-auto">
          <form @submit.prevent="handleSearch">
            <div id="search_input">
              <div class="input-group">
                
                <select class="form-select form-select-sm" id="search_brand" v-model="search_brand">
                  <option value="">Search by Brand</option>
                  <option v-for="item in brands" :value="item.mfid">{{item.brand_name}}</option>
                </select>
           
                <input v-model="search_keyword" id="search_keyword" type="text" class="form-control form-control-sm" placeholder="Mfr#, List#, SKU#, UPC" aria-label="Search" aria-describedby="button-addon2">
                <button class="btn btn-sm btn-outline-secondary" type="button" id="button-addon2" @click="handleSearch">
                  <i class="bi bi-search" style="font-size: 1rem"></i>
                </button>
              </div>
            </div>
          </form>
        </div>

        <!-- User and PO Cart -->
        <div id="user_PO" class="ms-auto" v-if="!user">
          <ul class="navbar-public navbar-nav mt-3 mt-lg-0 float-end" id="login_become">
            <li class="nav-item">
              <router-link :to="{name: 'Login'}" class="nav-link">Login</router-link>
            </li>
            <li class="nav-item"><a class="nav-link">/</a></li>
            <li class="nav-item" id="become_reseller">
              <router-link class="nav-link blue_button" :to="{name: 'BecomeReseller'}">Become a reseller</router-link>
            </li>
          </ul>
        </div>

        <div id="user_PO" class="ms-auto mt-3 mt-lg-0 float-end" v-else>
          <div class="btn-group" id="account_info">
            <a id="user_link" class="dropdown-toggle11" data-bs-toggle="dropdown" aria-expanded="true">
              <i class="bi bi-person-fill icon fs-3"></i>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li id="account_user"><strong class="ps-3 pe-3">{{ user }}</strong></li>
              <li><hr class="dropdown-divider"></li>
              <div v-if="is_customer">
              <li><router-link :to="{name: 'AccountOverview'}" class="dropdown-item ps-3 pe-3">Account</router-link></li>
              <li><hr class="dropdown-divider"></li>
              <li><router-link :to="{name: 'ShipmentTracking'}" class="dropdown-item ps-3 pe-3">Order Status</router-link></li>
              <li><hr class="dropdown-divider"></li>
              <li><router-link :to="{name: 'QuoteRequest'}" class="dropdown-item ps-3 pe-3">Quote Request</router-link></li>
              <li><hr class="dropdown-divider"></li>
              <li><router-link :to="{name: 'ChangePassword'}" class="dropdown-item ps-3 pe-3">Change Password</router-link></li>
              <li><hr class="dropdown-divider"></li>
              <li><router-link :to="{name: 'Newsletter'}" class="dropdown-item ps-3 pe-3">Email Newsletter</router-link></li>
              <li><hr class="dropdown-divider"></li>
              </div>
              <li><a class="dropdown-item ps-3 pe-3" @click="handleLogout" href="#">Logout</a></li>
            </ul>
          </div>

          <div v-if="is_customer" class="btn-group p-0">
            <router-link id="cart_link" :to="{name: 'Cart'}" class="position-relative">
              <i id="cart_icon" class="bi bi-cart icon fs-3"></i>
              <span v-if="cart" id="cart_qty" class="position-absolute top-0 start-10 translate-middle badge rounded-pill bg-danger">
              <span v-if="cart == 0 "></span>
              <span v-if="cart > 0 && cart <=99 ">{{cart}}</span>
              <span v-else-if="cart > 99" class="sm_font">99+</span>
              </span>
            </router-link>
          </div>
        </div>
        <!-- End User and PO Cart -->

      </div>
    </div>
  </nav>
</template>

<script>
import { ref, toRefs, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from "vue-router";
import $ from 'jquery'
import { getAPI } from '../../utils/axios-api'

export default {
  props: ['username', 'is_customer', 'cartQty'],
  setup(props, context) {
    const user = ref(null)
    const cart = ref(0)
    const is_customer = ref(null)
    const store = useStore()
    user.value = props.username
    cart.value = props.cartQty

    is_customer.value = props.is_customer
    
    const propsRef = toRefs(props)
    watch(propsRef.username, function(newValue, oldValue){
      user.value = newValue
    })
    watch(propsRef.is_customer, function(newValue, oldValue){
      is_customer.value = newValue
    })
    watch(propsRef.cartQty, function(newValue, oldValue){
      cart.value = newValue
    })
    
    //get category list
    const cate_hardware = ref(null)
    const cate_laptop = ref(null)
    const cate_electronic = ref(null)
    const category_levels = 5
    const category1_list = ref([])
    const category2_list = ref([])
    const category3_list = ref([])
    const category4_list = ref([])
    const category5_list = ref([])

    //Get level 1 category list
    getAPI
    .get("product/category-top/1/")
    .then(response => {
      category1_list.value = response.data["results"]
      //category2_list.value = category1_list.value[0]["sub_categories"]
    })
    .catch(err => {
      //error.value = err
    })

    //get manufacturers list
    const brands = ref([])
    getAPI
    .get("product/manufacturer/")
    .then(response => {
      brands.value = response.data["results"]
    })
    .catch(err => {
      //error.value = err
    })

    //logout
    const handleLogout = async () => {
      //log access (save this user action into log system)
      if (store.getters.isCustomer) {
          let result = await store.dispatch('logAccess', {'action':'logout'})
      }

      //process logout
      await store.dispatch('logout')
      user.value = null
      
      //emit a custom function to parent component to clear username
      context.emit('updateLogout')
    }
    
    //search
    const search_brand = ref("")
    const search_keyword = ref("")
    const router = useRouter()
    const handleSearch = () => {
      var url_params=""
      if (!!search_brand.value) {
        url_params = "mfid=" + search_brand.value + "&"
      }
      if (!!search_keyword.value) {
        //Modified on 3/14/2023 by Haini
        //If search string has + sign (etc. SD-USB3+1), + sign will be treat as a space in url, so
        //here we need to replace + sign with %2b to encode search string
        url_params = url_params + "search=" + search_keyword.value.replace('+', '%2b')
      }
      url_params = '/products/?' + url_params + "&ordering=-item__balance"
      router.push(url_params)
      
    }
  
    //This function is called when click "Products" in top nav bar
    const handleProductnav = (action) => {
      //reset the variables that store 2nd subcategory list and 3rd subcategory lsit 
      category2_list.value = []
      category3_list.value = []

      //reset the divs that show up in bigger window view
      clear_category_group_bkg(1)
      
      //toggle product nav menu
      var current_nav =  $('#navproducts')
      if (action == "toggle") {
        //current_nav.toggle()
        current_nav.slideToggle('fast')
      }
      else {
        current_nav.hide()
      }

      //reset the divs that show up in smaller window view 
      $("#sm_cate1").show()
      $("#sm_cate2").hide()
      $("#sm_cate3").hide()

      //hide other nav menus
      $("[name=subnav]").not(current_nav).hide();

    }
    
    
    const showCategoryLevel2 = (cate, id) => {
      
      //clear level 1 and level 2 styles
      clear_category_group_bkg(1)
      clear_category_group_bkg(2)

      //highlight current leve 1 item style
      highlight_category(1, id)

      //hide level 2 and 3 div
      $(".level2").hide()
      $(".level3").hide()

      //get level 2 category list
      get_subcategory(cate, 2)

      //show corresponding level 2 div
      let size = cate.sub_categories.length
      let top = cal_position(id, size) + "px"
      $("#cate1_" + id.toString()).find("#pointer").css("top", top)
      $("#cate1_" + id.toString()).find(".level2")
      .css("top", "-"+ top)
      .show()

      
      ///---------------- next processs divs for smaller window view -----------
      
      //hide level 1 and level3 category list
      $("#sm_cate1").hide()
      $("#sm_cate3").hide()

      //show level 2 category div
      let subcate_elm = $("#sm_cate2")
      subcate_elm.find(".subcate_top").show()
      subcate_elm.find(".subcate_name").html(cate.categoryname)
      subcate_elm.show()

      //set "Go back to parent category"
      $("#sm_cate3").find(".parentcate_name").html(cate.categoryname)
    }

    //after hide level 3, use this function when mouseover level 2 category in bigger view
    const doNotShowCategoryLevel3 = (id) => {
      //clear level 2 styles
      clear_category_group_bkg(2)

      //highlight current item style
      highlight_category(2, id)
    }

    const showCategoryLevel3 = (cate, id) => {
      
      //clear level 2 styles
      clear_category_group_bkg(2)

      //highlight current item style
      highlight_category(2, id)

      //hide all level 3 div
      $(".level3").hide()

      //get level 3 category list
      get_subcategory(cate, 3)

      //show corresponding level 3 div
      $("#cate2_" + id.toString()).find(".level3").show()

      ///---------------- next processs divs for smaller window view -----------
      
      //hide level 1 and level 2 category list
      $("#sm_cate1").hide()
      $("#sm_cate2").hide()

      //get level 3 category list 
      let subcate_elm = $("#sm_cate3")
      if (cate["sub_categories"].length > 0) {
        subcate_elm.find(".subcate_top").show()
        subcate_elm.find(".subcate_name").html(cate.categoryname)
        subcate_elm.show()
      }
      else {
        //need to hide the div if there is no sub category
        subcate_elm.hide()
      }
    }

    const showCategoryLevel2Sm = (cate, id) => {
      //hide level 1 and level3 category list
      $("#sm_cate1").hide()
      $("#sm_cate3").hide()

      //get level 2 category list 
      get_subcategory(cate, 2)

      //show level 2 category div
      let subcate_elm = $("#sm_cate2")
      subcate_elm.find(".subcate_top").show()
      subcate_elm.find(".subcate_name").html(cate.categoryname)
      subcate_elm.show()

      //set "Go back to parent category"
      $("#sm_cate3").find(".parentcate_name").html(cate.categoryname)

      ///---------------- next processs divs for smaller window view -----------

      //clear level 1 and level 2 styles
      clear_category_group_bkg(1)
      clear_category_group_bkg(2)

      //highlight current item style
      //highlight_category(1, id)

      //hide all level 3 div
      $(".level3").hide()

    }

    const showCategoryLevel3Sm = (cate, id) => {
      //hide level 1 and level 2 category list
      $("#sm_cate1").hide()
      $("#sm_cate2").hide()

      //get level 3 category list 
      get_subcategory(cate, 3)

      //get level 3 category list 
      let subcate_elm = $("#sm_cate3")
      subcate_elm.find(".subcate_top").show()
      subcate_elm.find(".subcate_name").html(cate.categoryname)
      subcate_elm.show()

      //show level 3 category div
      $("#sm_cate3").show()

      ///---------------- next processs divs for smaller window view -----------
      
      //clear level 2 styles
      clear_category_group_bkg(2)

      //highlight current item style
      highlight_category(2, id)

      //hide all level 3 div
      $(".level3").hide()

      //show corresponding level 3 div
      $("#cate2_" + id.toString()).find(".level3").show()
    }

    //Control back button
    const handleGoBackToCategory = (current_level, to_level) => {
      $("#sm_cate" + current_level.toString()).hide()
      $("#sm_cate" + to_level.toString()).show()
    }

    // after hide level 3, add this functin to toggle the color of the arrow in level 1 category when
    // mouseover it.
    const styleArrowLevel2Sm = (index, action) => {
      if (action == "mouseover") {
        $("[name='sm_cate1_arrow']").css("border-color", "#888")
        $("#sm_cate1_arrow_" + index.toString()).css("border-color", "#0d6efd")
      }
      else {
        $("[name='sm_cate1_arrow']").css("border-color", "#888")
      }
    } 

    /* ================ Common Functions ============== */

    /* This function clear styles for items that are in a certain level of category div.
       (This function is used for product category menu that shows in bigger window view.) */
    function clear_category_group_bkg(level) {
      if (level <= category_levels) {
        //get all items in curent level of category
        let cate_elms = $("[name=" + "cate" + level.toString() + "]")

        //reset the styles
        cate_elms.css("background-color", "")
        cate_elms.find("a").css("color", "#333")
        cate_elms.find("i").css( "border-color", "#888" )
        
        $(".level2").hide()
      }
    }

    /* This function highlight the curernt selected item.
       (This function is used for product category menu that shows in bigger window view.) */
    function highlight_category(level, id) {
        var bg_color = null
        var txt_color = "#0488cf"
        if (level == 1) {
          bg_color = "#0488cf"
          txt_color = "white"
        }
        else if (level == 2) {
          bg_color = "#888"
          txt_color = "white"
        }
        
        let cate_elm = $("#"+"cate" + level.toString() + "_" + id.toString())
        cate_elm.css("background-color", bg_color)
        cate_elm.find("a").not("[name=child]").css("color", txt_color)
        cate_elm.find(".arrow").css("border-color", txt_color)

    }

    /* This function assign subcategory list to corresponding global variables */
    function get_subcategory(cate, level){
      if (level == 2) {
        category2_list.value = cate["sub_categories"]
        category3_list.value = []
      }
      else if (level == 3) {
        category3_list.value = cate["sub_categories"]
      }
    }

    //this function calculate the position for div of level2 submenu
    function cal_position(current_level1_index, level2_size) {
      let unit = 40

      let level1_size = category1_list.value.length
      let current_level1_pos = current_level1_index + 1
      let current_level1_bottom_pos = level1_size - current_level1_pos

      let level2_pos = parseInt(level2_size/2)
      if (level2_size % 2 == 0) level2_pos = level2_pos - 1
      let level2_bottom_pos = level2_size - level2_pos - 1
      
      if (current_level1_pos <= level2_pos) {
        return 0
      }
      else {
        if (current_level1_bottom_pos >= level2_bottom_pos)
          return unit * level2_pos
        else {
          return unit * (level2_bottom_pos - current_level1_bottom_pos + level2_pos)
        }
      }
    }

    /* ================ End of Common Functions ============== */

    /* onMounted hook for menu lists other than product category menu */
    onMounted(() => {
      //toggle the grey/blue icons of submenu items
      $("[name=subnav_item]")
      .mouseover(function() {
        //$(this).find(".img_grey").toggle()
        //$(this).find(".img_blue").toggle()
        $(this).find(".icon").css("color", "#0d6efd")
      })
      .mouseout(function() {
        //$(this).find(".img_grey").toggle()
        //$(this).find(".img_blue").toggle()
        $(this).find(".icon").css("color", "#888")
      })

      //close product category div when click out of it. 
      $(window).click(function() {
        handleProductnav('hide');
      }) 

    })

    return { user, 
            is_customer,
            cart, 
            cate_hardware,
            cate_laptop,
            cate_electronic,
            category1_list,
            category2_list,
            category3_list,
            category4_list,
            category5_list,
            brands,
            search_brand,
            search_keyword,
            handleLogout,
            handleSearch,
            handleProductnav,
            handleGoBackToCategory, 
            showCategoryLevel2,
            showCategoryLevel3,
            showCategoryLevel2Sm,
            showCategoryLevel3Sm,
            doNotShowCategoryLevel3,
            styleArrowLevel2Sm
         }
  },

  methods: {
    showSubnav: function(category) {
      var current_nav =  $('#nav' + category)

      //toggle current nav menu
      current_nav.slideToggle("fast")

      //hide other nav menus
      $("[name=subnav]").not(current_nav).slideUp("fast");
      $('.submenu').css({'display': 'none'});
      $('.submenu_sm').css({'display': 'none'});
    },

    hideSubnav() {
      //when click on subnav item, hide all subnav menus
      $('.subnav').css({'height': 'auto', 'display': 'none'});
      $('.submenu').css({'display': 'none'});
      $('.submenu_sm').css({'display': 'none'});
      
      //trigger the click event of the navbar-toggler so that the navbar collapse for small screen
      $('.navbar-toggler').click(); 
    },

    //toggle the second level menu under subnav menu
    toggleSecSubList: function(menu) {
      var current_nav =  $('#sublist_' + menu)

      //toggle current nav menu
      current_nav.slideToggle("fast")
    },

    showSubList: function(menu) {
      var current_nav =  $('#sublist_' + menu)

      //toggle current nav menu
      current_nav.show("fast")

      //hide other nav menus
      //$("[name=subnav]").not(current_nav).slideUp("fast");
    },

    hideSubList: function(menu) {
      var current_nav =  $('#sublist_' + menu)

      //toggle current nav menu
      current_nav.hide("fast")

      //hide other nav menus
      //$("[name=subnav]").not(current_nav).slideUp("fast");
    },
    
  }
}
</script>


<style scoped>
  .container-fluid, .navbar {
    padding: 0 !important;
  }
  .navbar {
    position: relative;
    z-index: 10;
    min-width: 300px;
    max-width: 100%;
    height: 90px;

    padding: 15px 40px !important;
    /* background-color: #265197; */
  }
  @media (min-width: 992px){
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 0.5rem;
  }}
  nav > .container {
    padding: 0 !important;
    max-height: 90px;
  }
  .navbar-collapse {
    background: #fff;
  }
  @media (max-width: 991px) {
    .navbar-collapse {
      position: absolute;
      top: 90px;
      left: 0;
      right: 0;
      padding: 0px 40px 30px;
    }
  }

  .navbar-brand > img {
    position: relative;
    top: -2px;
    max-width: 200px;
  }
  .nav-link {
    font-family: Roboto Bold;
    font-size: 16px;
    /* color: #fff !important; */
    color: #000 !important;
    /*text-transform: uppercase;*/
  }
  .nav-item {
    margin-right:0px;
  }
  .dropdown-toggle::after {
    /* opacity: 0.5; */
     color:#0494E4 !important;/*#1f49e0; */
    margin-left:5px;
    margin-top:5px;
    font-size:20px;
  }

  .navbar-public .nav-link {
    font-family: Segoe;
    font-size: 12px;
    padding: 0 5px !important;
  }
  .navbar-public li:first-child .nav-link {
    padding-left: 10px !important;
  }
  .navbar-public li:last-child .nav-link {
    padding-right: 0 !important;
  }

  .subnav {
    display: none;
    position: absolute;
    top: 45px;
    left: 0;
    z-index: 2;
    width: 300px;
    border:2px solid #ccc;
    border-radius: 4px;
    background-color:#fff;
    padding: 10px 0 !important;
  }
  .subnav span {
    display: inline-block;
    padding-left:20px;
    font-size: 14px;
  }
  .subnav i {
    font-size:25px;
  }
  .subnav a {
    border-width:0;
    /* padding:15px 15px 15px 30px; */
  }
  .subnav a:hover {
    color:#0d6efd;
  }
  .subnav img {
    width:45px;
  }
  .subnav .list-group {
    position: relative;
    z-index: 2;
  }

  .dropdown-item {
    padding: 0;
  }
  
  .dropdown-menu{
    padding: 10px;
    border-color: #ccc;
    border-width: 2px;
    padding:10px 0;
    margin:0px !important;
  }
  .dropdown-menu li {
    
  }

  .dropdown-toggle:after {
    vertical-align: 3px !important;
  }

 #navproducts {
    display: none;
    position: absolute;
    top: 60px;
    color: #333;
    
  }
  @media (max-width:1000px) {
    #navproducts {
    top: 100px;
    }
  }
  @media (min-width:1500px) {
    #navproducts {
    top: 83px;
    }
  } 

  #navproducts li {
    border-width:0 !important;
    border-bottom: 1px solid #D3D3D3 !important;
    background: #fff;
    z-index: 2;
  }
  #navproducts li:last-child {
    border-bottom-width: 0 !important;
  }
  #navproducts .links,
  #navproducts .links_sm {
    position: relative;
    z-index: 2;
    float: left;
    border-radius: 6px;
    border:2px solid #ccc;
    background-color: #fff;
  }
  #navproducts .links li a,
  #navproducts .links_sm li a
   {
    display:block;
    padding:1px 0px;
    width: 230px;
    text-decoration: none;
    font-size: 14px;
    color:#333;
    border-width:0 !important;
  }
  #navproducts .links_sm li a{
    cursor: pointer;
  }
  #navproducts .links_sm li a:hover{
    color: #0d6efd !important;
  }
  #navproducts #sm_cate2 li:last-child a,
  #navproducts #sm_cate3 li:last-child a
  {
    padding-bottom:15px;
  }

  #navproducts .level2 {
    position:absolute;
    top:-41px;
    left: 296px;
    display:none;
    padding-left:10px;
  }
  #navproducts .level3 {
    position:absolute;
    top:-2px;
    left: 296px;
    display:none;
    padding-left:10px;
  }
  #navproducts .level2 a:hover {
    color: #0d6efd !important;
  }
  #navproducts .level3 a:hover {
    color: #0d6efd !important;
  }
  #pointer {
    float:left;
    top:0;
    left:-4px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-right: 10px solid white;
    border-bottom: 10px solid transparent;
    position: relative;
    margin:10px 0 0 -16px;
  }
  .arrow_pointer {
    float:left;
    top:9px;
    left:6px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-right: 10px solid #0d6efd;
    border-bottom: 10px solid transparent;
    position: relative;
    margin:10px 0 0 -16px;
  }
  .badge {
    display:inline-block;
    padding-top:8px;
    color:#222;
  }
  /* after hide level 3, add this class to level 1 arrow pointing right in small view ,
  in order to make it clickable so that when it is clicked, the 2nd level shows
  */
  .clickable {
    cursor: pointer;
  }
  .arrow {
    border-style: solid;
    border-color:#888;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }
  .arrow_right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  }
  .arrow_left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  }
  .arrow_down {
    margin-top:-5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  }
  .img_blue {display:none;}
  .subcate_top{
    display:none;
    padding: 20px 20px 10px 25px;
    border-bottom:1px solid #D3D3D3;
  }
  .subcate_top a{
    cursor: pointer;
    display:block;
    padding-bottom:15px;
  }
  .subcate_back{
    padding-bottom:30px;
  }
  .subcate_name {
    font-size:18px;
    font-weight: bold;
  }
  #sm_cate2, #sm_cate3 {
    display: none;
  }
  .icon {
    padding-left:10px;
    font-size:2rem;
    color:#888;
  }

  @media (max-width:1500px) {
    #product_links {
      display:none;
    }
  }
  @media (min-width:1500px) {
    #product_links_sm {
      display:none;
    }
  }

  

  /* .user {
    position: absolute;
    right: 20px;
    top: 25px;
    color: aliceblue;
  }
  .user a {
    color: #fff;
  }
  ul #user {
    position: absolute;
    right: 8px;
    top: 8px;
    color: aliceblue;
  } */

  #login_become {
    padding-left:0;
  }
  #login_become li {
    margin:0;
  }
  #login_become li:not(:last-child) {
    padding: 5px 0;
  }
  #become_reseller {
    padding: 5px 20px;
    background: #0069A7; /*#0078B9;*/
    border-radius: 5px;
    height: 28px;
    margin-left:10px !important;
    text-align: center;
    white-space: nowrap;
  }
  #become_reseller a {
    color: #fff !important;
  }
  @media (max-width: 991px) {
    #login_become {
      flex-direction: row;
    }
  }


  #search_form {
    position: relative;
    z-index: 1;
  }
  @media (min-width: 1650px) {
    #search_form {
      padding-left: 20px;
      padding-right: 20px;
      max-width: 600px;
    }
  }
  @media (min-width: 992px) and (max-width: 1650px) {
    #search_form {
      position: absolute;
      top: 80px;
      left: 260px;
      width: 100%;
      height: 48px;
      max-width: 630px;
    }
  }
  @media (min-width: 992px) and (max-width: 1150px) {
    #search_form {
      max-width: calc(100% - 550px);
    }
    #user_PO {
      position: absolute;
      top: 80px;
      right: 40px;
    }
  }
  #user_PO {
    left: auto !important
  }

  #search_brand {
    width: 20% !important;
    max-width: 150px;
  }
  #search_input {
    min-width: 200px;
  }



  #account_info ul {
    z-index: 2;
    min-width: 0px;
    padding: 0;
    white-space: nowrap;
    min-width: 250px;
  }
  @media (max-width: 991px) {
    #account_info ul {
      left: 0;
      width: calc(100vw - 137px);
    }
  }
  #account_user {
    width: 100% !important;
    padding: 10px 0;
  }
  #account_info ul .dropdown-divider {
    margin: 0;
  }
  #account_info ul li a {
    padding: 10px 20px;
  }
  

  #cart_icon {
    font-size: 1.8rem; 
    color: cornflowerblue;
  }
  #cart_qty{
    top:8px !important;
    color:white;
    font-size:11px;
    padding:0px 0px;
    width:22px;
    height:22px;
    text-align: center;
    vertical-align: middle;
    line-height: 22px;
  }
  .sm_font {font-size:10px;}


  .small_boxs {
    height:3px !important;
  }
  #button-addon2 {
    height:31px ;
    background-color:#ccc;
  }
  #button-addon2:hover{
    color:#333;
  }
  #user_link {
    cursor:pointer;
  }
  
  /*  Solution sub menu */
  .submenu {
    display: none;
    position: absolute;
    top: 0;
    left: 101%; /* Position the submenu to the right of the parent */
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.submenu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 14px;
}

.submenu li a {
    display: block;
    color:#222222;
    padding: 10px 20px !important;
    text-decoration: none;
}

.submenu li a:hover {
    background-color: #f8f9fa;
}

.submenu_sm ul {
    list-style-type: none;
    margin: 0;
    font-size: 14px;
    background-color: #fdfdfd;
}
.submenu_sm li a {
    display: block;
    color:#222222;
    padding: 5px 10px 5px 70px !important;
    text-decoration: none;
}
.submenu_sm li a:hover {
    background-color: #f8f9fa;
}

@media (min-width: 1500px) {
    .subnav_sm {
        display: none;
    }
}

@media (max-width: 1500px) {
    .subnav_lg {
        display: none;
    }
}
</style>