import store from '../store/index'
import { getAPI } from '../utils/axios-api'

const jwtDecrypt = (token) => {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    
    return JSON.parse(jsonPayload);
}

const tokenAlive = (exp) => {
    const now = new Date().getTime()
    const dateObject = new Date(exp*1000)
    const nowObject = new Date(now)
    //console.log("get time: ", now.getTime())
    //console.log("now: "+now, nowObject.toLocaleString())
    //console.log("exp: "+exp*1000, dateObject.toLocaleString())
    if (now >= exp * 1000) {
      return false;
    }
    return true;
}

const addDays = (time, days) => {
    return time + days * 24 * 60 * 60
}

//add javascript for google reCaptcha into page template
//param: id - a HTML element id, the gogole reCaptcha javascript will be appended into this HTML element.
const insertReCaptchaScript = (id) => {
    let elm = document.createElement('script');    
    //elm.setAttribute('src',"https://www.google.com/recaptcha/api.js?render=" + store.getters.reCaptchaSiteKey);
    elm.setAttribute('src',"https://www.recaptcha.net/recaptcha/api.js?render=" + store.getters.reCaptchaSiteKey);
    document.getElementById("google_recaptcha").appendChild(elm);
}

const inserCFTurnstileScript = () => {
    var elm = document.getElementById("cf-recaptcha-script");
    if (elm) elm.remove();

    let cf_elm = document.createElement('script');
    cf_elm.id = 'cf-recaptcha-script';
    cf_elm.setAttribute('src',"https://challenges.cloudflare.com/turnstile/v0/api.js");
    cf_elm.async = true;
    cf_elm.defer = true;
    cf_elm.onload = () => {
        // Initialize Turnstile when the script is loaded
        turnstileInstance.value = Turnstile.render(turnstileElement.value, {
          sitekey: siteKey,
          callback: (token) => {
            console.log('============Turnstile token=========:', token);
          },
        });
    };
    //document.getElementById(id).appendChild(cf_elm);
    document.head.appendChild(cf_elm);
}

//This function will generate reCaptcha token and send it to server API to verfy it.
//Return the result from server
const handleReCaptcha = async (grecaptcha) => {
    return new Promise((resolve, reject) => {
        //get token for google reCaptcha and send it to server to verify
        grecaptcha.ready(() => {
            
            grecaptcha
            .execute(store.getters.reCaptchaSiteKey, {
                action: "submit"
            })
            .then(token => {
                const payload = { token: token };
                
                //send token to server to verify it
                getAPI
                .post("/account/verify-captcha/", payload)
                .then(response => {
                    try {
                        let result = {is_human: response.data.is_human,
                                        message: response.data.message}
                        resolve (result)
                    } catch {
                        resolve ({status: false, message: "Failed to verify if this is a human."})
                    }
                    
                })
                .catch(error => {
                    resolve ({status: false, message: "Something is wrong. Failed to verify if this is a human."})
                })

            })
            .catch(err => {
                resolve ({status: false, message: "cannot get google reCaptcha token."})
            });
            
        });
    })
}

const loadTurnstileScript = () => {
    return new Promise((resolve, reject) => {
        let element = document.getElementById('cf-turnstile');
        if (element) {
            element.remove();
        }
        const script = document.createElement('script');
        script.id = 'cf-turnstile';
        script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
        script.async = true;
        script.defer = true;
        script.onload = () => resolve();
        script.onerror = (err) => reject(err);
        document.body.appendChild(script);
    });
}
const loadTurnstileElement = async (turnstile, cfelm) => {
    return new Promise((resolve, reject) => {
        turnstile.remove()
        turnstile.render(cfelm, {
            sitekey: store.getters.cfSiteKey,
            callback: function(token) {
                resolve ({token: token})
            },
        });
    })
}
const handleTurnstile = async (turnstile) => {
    return new Promise((resolve, reject) => {

        try {
            var token = turnstile.getResponse()
            const payload = { token: token };
            
            //send token to server to verify it
            getAPI
            .post("/account/verify-turnstile/", payload)
            .then(response => {
                try {
                    
                    let result = {is_human: response.data.success,
                                    message: response.data.message,
                                    error_code: "We couldn't verify if you're human. Please try again to confirm you're not a robot. You may need to refresh the page."}
                    resolve (result)
                } 
                catch (err) {
                    let result = {is_human: false,
                        message: "",
                        error_code: "Failed to verify if you're human. Error: " + err
                    }
                    resolve (result)
                }
                
            })
            .catch(error => {
                let result = {is_human: false,
                    message: "",
                    error_code: "Failed to verify if you're human. Error 2: " + error}
                resolve (result)
            })
        }
        catch (err) {
            let result = {is_human: false,
                message: "",
                error_code: "Failed to verify if you're human. Error 3: " + err
            }
            resolve (result)
        }

    })
}

const checkEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email) == false) return false;
    else return true;
}

// format number to currency.
const formatPrice = (value) => {
    var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",

        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter.format(value);
}

const branchName = (branch_code) => {
    var branch_name = "N/A"
    var branch = store.getters.branch
    for (let key in branch) {
        if (branch_code == branch[key]["code"]) {
            branch_name = branch[key]["name"]
            break
        }
    }
    return branch_name
}

const detectBrowser = () => {
    var ua=navigator.userAgent,tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []; 
    if(/trident/i.test(M[1])){
        tem=/\brv[ :]+(\d+)/g.exec(ua) || []; 
        return {name:'IE',version:(tem[1]||'')};
        }   
    if(M[1]==='Chrome'){
        tem=ua.match(/\bOPR|Edge\/(\d+)/)
        if(tem!=null)   {return {name:'Opera', version:tem[1]};}
        }   
    M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}
    return {
      name: M[0],
      version: M[1]
    };
}

//check if a certain cookie exists or not
const getCookie = (cname) => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length,c.length);
      }
    }
    return "";
  }

//convert datetime object into string in this format: mm/dd/YYYY HH:MM:SS
const formatDateTime = (d) => {
    var year = d.getFullYear()
    var day = d.getDate()
    var month = d.getMonth() + 1
    var hour = d.getHours()
    var minute = d.getMinutes()
    var second = d.getSeconds()

    year = year.toString()
    
    if (day < 10) day = "0" + day.toString()
    else day = day.toString()

    if (month < 10) month = "0" + month.toString()
    else month = month.toString()

    if (hour < 10) hour = "0" + hour.toString()
    else hour = hour.toString()

    if (minute < 10) minute = "0" + minute.toString()
    else minute = minute.toString()

    if (second < 10) second = "0" + second.toString()
    else second = second.toString()

    let result = month + "/" + day + "/" + year + " " + hour + ":" + minute + ":" + second
    return result
}

function isInteger(value) {
    if ( Number.isInteger(value) ) {
        if (value >0 ) return true
        else return false
    }
    else {
        if (value.indexOf(".") !== -1 || value.indexOf("-") !== -1) {
            return false
        }
        else if (isNaN(parseInt(value))) {
            return false
        }
        else {
            //only contains digits
            return /^\d+$/.test(value)
        }
    }
}

//return the list like the structure below:
// [
//     {
//         "atrid":"A00035",
//         "valid": ["K270854", "K181271"]
//     },
//     {
//         "atrid":"A03483",
//         "valid": ["K558467"]
//     }
// ]
function parseFilterAtrList(url){
    var atrvals =  []
    var args = []
    try {
        //filter3a is like &filter3a=A00140:K00686,K11588--A05858:K361046,K361048
        args = url.split("&filter3a=")[1].split("--")
    }
    catch(e) {
        return atrvals
    }

    var atr, atrid, value_list
    for (var i = 0; i < args.length; i++) {
        try {
            atr = []
            atr = args[i].split(":")
        }
        catch {
            continue
        }

        if (!Array.isArray(atr)) continue
        if (atr.length == 0) continue

        atrid = atr[0]
        try {
            value_list = atr[1].split(",")
        }
        catch {
            value_list = []
        }
        atrvals.push({"atrid": atrid, "valid": value_list})  
        
    }
    
    return atrvals
}

//make first letter of a string upper case
function capitalize(s)
{
    return s && s[0].toUpperCase() + s.slice(1);
}

//

export { 
        jwtDecrypt,
        tokenAlive,
        addDays,
        insertReCaptchaScript, 
        inserCFTurnstileScript,
        handleReCaptcha, 
        handleTurnstile,
        checkEmail, 
        formatPrice,
        branchName, 
        detectBrowser,
        getCookie,
        formatDateTime,
        isInteger,
        parseFilterAtrList,
        capitalize,

        loadTurnstileScript,
        loadTurnstileElement,
     }