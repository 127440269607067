<template>
    <section id="footer">
      <div class="section-container">
        
        <!-- Links -->
        <div id="footer_links">
          <div class="wrapper">
            <div class="card">
              <h3><a href="#!">Products</a></h3>
              <ul>
                <!-- do not't change code, just send Bo's pdf and bo will upload the new pdf to media/documents/linecard.pdf  -->
                <li><a href="/media/catalog/index.html" target="blank">Catalog</a></li>
                <li><a href= "/media/documents/linecard.pdf" target="blank">Line Card</a></li>
              </ul>
            </div>
          </div>

          <div class="wrapper">
            <div class="card">
              <h3><a href="#!">Solutions</a></h3>
              <ul>
                <li><router-link :to="{ name: 'ServerSolutions' }">Server Solutions</router-link></li>
                <!-- <li><router-link :to="{ name: 'MiningSolutions' }">Mining Solutions</router-link></li> -->
                <li><router-link :to="{ name: 'IndustrialSolutions' }">Industrial Solutions</router-link></li>
                <li><router-link :to="{ name: 'GamingSolutions' }">Gameing Solutions</router-link></li>               
                <li><router-link :to="{ name: 'EdgeSolutions' }">Edge Solutions</router-link></li>
                <li><router-link :to="{ name: 'DigitalSolutions' }">Digital Solutions</router-link></li>
              </ul>
            </div>
          </div>

          <div class="wrapper">
            <div class="card">
              <h3><a href="#!">Partners</a></h3>
              <ul>
                <!-- <li><router-link :to="{ name: 'Partners' }">Our Partners</router-link></li> -->
                <li><router-link :to="{ name: 'FeaturedStores' }">Featured Stores</router-link></li>

              </ul>
            </div>
          </div>

          <div class="wrapper">
            <div class="card">
              <h3><a href="#!">Company</a></h3>
              <ul>
                <li><router-link :to="{ name: 'About' }">Overview</router-link></li>
                <li><router-link :to="{ name: 'RecognitionsAndSpotlights' }">Recognitions & Spotlights</router-link></li>
                <li><router-link :to="{ name: 'Events' }">Events</router-link></li>
                <li><router-link :to="{ name: 'Careers' }">Careers</router-link></li>
                <li><router-link :to="{ name: 'EmailNewsletters' }">Email Newsletters</router-link></li>
                <li><router-link :to="{ name: 'Branches' }">Our Branches</router-link></li>
                <li><a href ="/media/catalog/logo.zip">Logo</a></li>
              </ul>
            </div>
          </div>

          <div class="wrapper">
            <div class="card">
              <h3><a href="#!">My Account</a></h3>
              <ul>
                <li><router-link :to="{ name: 'AccountOverview' }">Account Management</router-link></li>
                <li><router-link :to="{ name: 'RegisterAccountLink' }">Web Account Register</router-link></li>
                <li><router-link :to="{ name: 'Cart' }">Shopping Cart</router-link></li>
                <li><router-link :to="{ name: 'ShipmentTracking'}">Order Status</router-link></li>
              </ul>
            </div>
          </div>

          <div class="wrapper">
            <div class="card">
              <h3><a href="#!">Support</a></h3>
              <ul>               
                <li><router-link :to="{ name: 'ContactUs',params: { topic:'-' }}">Contact US</router-link></li>
                <li><router-link :to="{ name: 'RMARequest' }">RMA Request</router-link></li>
                <li><router-link :to="{ name: 'FAQ' }">FAQ</router-link></li>
              </ul>
            </div>
          </div>
        </div>


        <!-- Copyright -->
        <div id="copyright">
          <p>Copyright &copy;  Ma Laboratories, Inc. All Rights Reserved</p>
          <div>
            <router-link :to="{ name: 'Disclaimer'}">Disclaimer</router-link>| 
            <router-link :to="{ name: 'TermsAndConditions'}">Terms&nbsp;And&nbsp;Conditions</router-link> | 
            <router-link :to="{ name: 'PrivacyPolicy'}">Privacy&nbsp;Policy</router-link> | 
            <router-link :to="{ name: 'CookieNotice'}">Cookie&nbsp;Notice</router-link>
          </div>
        </div>

      </div>
    </section>

    <!-- cookie accept layer -->
    <div id="cookies" style="z-index: 9999">
			<p>
				We use cookies to improve your experience on our site, analyse site traffic and to show you relevant content. By using our website, you consent to our use of cookies. For more information please, see our <a href="/company/privacy-policy">Privacy Policy</a> and <a href="/company/cookie-notice">Cookie Notice</a>.
			</p>
			<button class="btn pull-right" @click="acceptCookies">Accept</button>
			<form id="cookie_notice" method="POST">
				<input type="hidden" id="accept_cookie" name="accept_cookie">
			</form>
			
		</div>


    <!-- newsletter subscribe layer -->
    <div id="subscribe" class="stick_bottom" style="z-index: 9999;">
      <div class="center">

          <div class="center_content">
            <div class="float me-3"><img src="@/assets/images/notebook_image.png" /></div>
            <div class="float me-3"><img src="@/assets/images/gift_card_image.png" /></div>
            <div class="float me-3">
              <table>
                <tr>
                  <td class="subscribe_text">Subscribe to Our Newsletter to win a <span class="gold">Notebook</span> or <span class="gold">Gift Card!</span></td>
                </tr>
                <tr>
                  <td align="right" class="subscribe_text_sm">*For more details, please refer to our <a href="https://www.subscribepage.com/signup-to-win" target="_blank">Terms & Conditions.</a></td>
                </tr>
              </table>
            </div>
            <div class="float"><a class="btn subscribe_btn" href="/company/newsletter">Subscribe</a></div>
          </div>

          <a id="close_x" @click="closeSubscribe"><i class="bi bi-x-circle-fill"></i></a>
      </div>
    
	</div>
</template>


<script>
import { getAPI } from '../../utils/axios-api'
import { getCookie, formatDateTime } from '../../utils/common'
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { BackendDomain } from "../../utils/settings.js";

export default {
  setup(props, context) {
    const store = useStore()
    const isSubscribed = store.getters.isSubscribed
    const user = JSON.parse(localStorage.getItem("user"))
    const emailaddr = ref(null)
    
    function showSubscribe() {
      //turn off subscribe layer on 6/6/2023, requested by Elena since the event is over
      return false

      //no need to show subscribe layer when user logged in and is subscribed already
      if (!!user && isSubscribed) return false

      //if user not logged in, or user is not subscribed, check if user has previously closed subscribe layer. 
      //if user closed it already, do not to show it again.
      if (getCookie('subscribeclose')) return false

      return true
    }


    onMounted(() => {

      //Check if malabs cookie notice is accepted or not
      //If not, show cookie notice accept button
      if (getCookie('cookienotice') == "") {
        let tag = document.getElementById("cookies");
        tag.style = "display:table;"
      }

      //if cookie notice is accepted, show subscribe layer
      if (getCookie('cookienotice')) {
        if (showSubscribe()) {
          let tag = document.getElementById("subscribe");
          tag.style = "display:table;"
        }
      }

      //added ada by zoe

      const script = document.createElement('script');
      const head = document.querySelector('head') || document.body;
      console.log(head)
      script.src = 'https://acsbapp.com/apps/app/dist/js/app.js';
      script.async = true;
      script.onload = () => {
        acsbJS.init({
          statementLink: '',
          footerHtml: '',
          hideMobile: false,
          hideTrigger: false,
          disableBgProcess: false,
          language: 'en',
          position: 'right',
          leadColor: '#146FF8',
          triggerColor: '#146FF8',
          triggerRadius: '50%',
          triggerPositionX: 'right',
          triggerPositionY: 'bottom',
          triggerIcon: 'people',
          triggerSize: 'bottom',
          triggerOffsetX: 20,
          triggerOffsetY: 20,
          mobile: {
            triggerSize: 'small',
            triggerPositionX: 'right',
            triggerPositionY: 'bottom',
            triggerOffsetX: 10,
            triggerOffsetY: 10,
            triggerRadius: '20',
          },
        });
      };
      head.appendChild(script);

    });
    
    
    //accept cookie
    const acceptCookies = () => {
      var d = new Date();
      var cur_timestamp = formatDateTime(d)

      var exdays = 3650;
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      var expires = "expires="+ d.toUTCString();

      document.cookie = "cookienotice=1" + ";" + expires + "; path=/";
      document.cookie = "cookienoticetime=" + cur_timestamp + ";" + expires + "; path=/";

      let tag = document.getElementById("cookies");
      tag.style = "display:none;"
    
      //if cookie notice is accepted, show subscribe layer
      if (getCookie('cookienotice')) {
        if (showSubscribe()) {
          let tag = document.getElementById("subscribe");
          tag.style = "display:table;"
        }
      }
      
    }

    const closeSubscribe = () => {
      var d = new Date();
      var cur_timestamp = formatDateTime(d)

      var exdays = 3650;
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      var expires = "expires="+ d.toUTCString();

      document.cookie = "subscribeclose=1" + ";" + expires + "; path=/";
      document.cookie = "subscribeclosetime=" + cur_timestamp + ";" + expires + "; path=/";

      let tag = document.getElementById("subscribe");
      tag.style = "display:none;"
    }



    return {acceptCookies,BackendDomain, closeSubscribe, emailaddr};

  },
};
</script>


<style scoped>
#footer {
  position: relative;
  min-width: 300px;
  min-height: 100vh;
  padding: 65px 30px;

  font-family: Segue, Arial;
  color: #575757;
  background: #E8E8E8;
  clear: both;
}
@media (min-width: 787px) {
  #footer {
    padding: 65px 100px;
  }
}


#footer a {
  color: #aaa;
  font-size: 16px;
}
#footer a:hover {
  color: #aaa !important;
  text-decoration: underline;
}
#footer h3 {
  font-size: 18px;
  text-transform: none;
}
#footer h3 a {
  color: #141414 !important;
}
#footer h3 a:hover {
  color: #141414 !important;
  text-decoration: underline;
}


/* Desktop */
@media (min-width: 1035px) {
  #footer {
    min-height: 0;
  }
}


/* Footer links */
#footer_links {
  position: relative;
  font-size: 12px;
  overflow: hidden;
}
#footer_links .wrapper {
  float: left;
  width: 50%;
  height: 375px;
}
#footer_links .wrapper:nth-child(n+3):nth-child(-n+4) {
  height: 280px;
}
#footer_links .card {
  padding: 0;
  background: none;
  border: none;
}
#footer_links h3 {
  font-family: Roboto Bold;
  color: #fff;
  font-size: 16px;
}
#footer_links a {
  color: #aaa;
  font-size: 16px;
  text-decoration: none;
}
#footer_links .card ul {
  display: table;
  padding: 0 2px;
  list-style-type: none;
}
#footer_links .card li {
  margin: 10px 0;
}

#cookies {
	display: none;
	z-index: 70;
	position: fixed;
	bottom: 0;
	width: 100%;
	padding: 20px 30px;
	background: rgba(80, 80, 80, 0.85);
}
#cookies p {
	float: left;
	width: calc(100% - 200px - 40px);
	color: #fff;
}
#cookies a {
	color: #fff;
	text-decoration: underline;
}
#cookies button {
	position: absolute;
	top: calc(50% - 17px);
	right: 100px;
	padding: 5px 30px;

	color: #000;
	background: #fff;
	font-family: Roboto Medium;
	font-size: 16px;

	border: 1px solid #fff;
	border-radius: 4px;
}

.stick_bottom {
	display: none;
	z-index: 70;
	position: fixed;
	bottom: 0;
	width: 100%;
}
.stick_bottom p {
	float: left;
	width: calc(100% - 112px - 40px);
	color: #fff;
}
.stick_bottom a {
	color: #fff;
	text-decoration: underline;
}
.stick_bottom label {
  color: #fff;
  padding-top:0.5em;
}
.center{
  position: relative;
  width:90%;
  margin: auto;
  padding: 20px 30px 10px;
  background: rgba(11, 32, 48, 0.87);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.center_content {
  display:table; margin:auto;
}
.subscribe_text {
  font-family: Segoe, Arial;
  font-weight: bold;
  font-size:25px;
  color:#d4d4d4;
  letter-spacing: 1px;
}
.subscribe_text_sm {
  font-family: Segoe, Arial;
  font-size:10pt;
  color:#fff;
}
.subscribe_btn {
  width:190px;
  height:48px;
  background-image:url('~@/assets/images/orange_button.png');
  opacity:1;
  color:#fff;
  font-size:22px;
  font-family: Segoe, Arial;
  font-weight: bold;
  letter-spacing: 1px;
}
.subscribe_btn:link {text-decoration: none;}
.subscribe_btn:hover {color:#d4d4d4;text-decoration: none;}
.float {float: left;}
.gold {color:#ffc12a;}
#close_x {
  position: absolute;
  top:10px;
  right:15px;
  color:#888;
  cursor: pointer;
}
#close_x:hover {
  color:#eee;
}

@media (max-width: 530px) {
  #footer_links .wrapper .card {
    float: none;
    display: table;
    margin: 0 auto;
  }
}

@media (min-width: 531px) and (max-width: 1199px) {
  #footer_links .wrapper {
    width: 33.333%;
    height: 330px;
  }
  #footer_links .wrapper:nth-child(n+3):nth-child(-n+4) {
    height: 330px;
  }
  #footer_links .wrapper:nth-child(2) .card,
  #footer_links .wrapper:nth-child(5) .card {
    float: none;
    display: table;
    margin: 0 auto;
  }
  #footer_links .wrapper:nth-child(3) .card,
  #footer_links .wrapper:nth-child(6) .card {
    float: right;
  }
}
@media (min-width: 1200px) and (max-width: 1474px) {
  #footer_links .wrapper {
    width: 20%;
  }
  #footer_links .wrapper:nth-child(3) .card {
    float: none;
    display: table;
    margin: 0 auto;
  }
  #footer_links .wrapper:nth-child(4) .card,
  #footer_links .wrapper:nth-child(5) .card {
    float: right;
  }
}
@media (min-width: 1475px) {
  #footer_links .wrapper {
    width: 14.28571428571429%;
    height: auto;
  }
  #footer_links .wrapper:nth-child(4) .card {
    float: none;
    display: table;
    margin: 0 auto;
  }
  #footer_links .wrapper:nth-child(n+5) .card {
    float: right;
  }
}


/* Copyright */
#copyright {
  margin-top: 30px;
  color: #888;
  font-size: 14px;
  text-align: center;
}
#copyright a {
  margin: 0 10px;
  font-size: 14px;
  color: #888;
}
#copyright a:last-child {
  margin: 0 0 0 10px;
}
#copyright .mobile-divider {
  display: block;
  width: 0;
  height: 10px;
  opacity: 0;
}


@media (min-width: 475px) {
  #copyright .mobile-divider {
    display: inline-block;
    width: auto;
    opacity: 1;
  }
}
@media (min-width: 1035px) {
  #copyright p {
    float: left;
  }
  #copyright > div {
    float: right;
  }
}

</style>
