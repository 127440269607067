import {  tokenAlive } from "../../../utils/common";
import { cfSiteKey } from "../../../utils/settings";

export default {
  user(state) {
    return state.user
  },
  isCustomer(state) {
    try {
      if (state.user.groups.indexOf("Customers")>=0) { return true }
      else { return false }
    }
    catch {
      return false
    }
  },
  isVendor(state) {
    try {
      if (state.user.groups.indexOf("Vendors")>=0) { return true }
      else { return false }
    }
    catch {
      return false
    }
  },
  isSubscribed(state) {
    return state.user.eblast
  },
  userName(state) {
    if (state.user.groups.includes("Customers")) { return state.user.company }
    else { return state.user.name }
  },
  custno(state) {
    if (state.user.groups.includes("Customers")) { return state.user.custno }
    else { return "" }
  },
  userId(state) {
    return state.user.id
  },
  userEmail(state) {
    if (state.user.groups.includes("Customers")) { return state.user.username }
    else { return "" }
  },
  sales(state) {
    return state.user.sales
  },
  accessToken(state) {
    return state.accessToken
  },
  authStatus(state) {
    return state.authStatus
  },
  isTokenExpired(state) {
    if (!state.tokenExp || !state.accessToken) {
      return false
    }
    return tokenAlive(state.tokenExp);
  },
  isAuthenticated(state) {
    if (!state.refreshExp) {
      return false
    }
    return tokenAlive(state.refreshExp)
  },
  reCaptchaSiteKey(state) {
    return state.reCaptchaSiteKey
  },
  cfSiteKey(state) {
    return state.cfSiteKey
  }
};