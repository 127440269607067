import { createRouter, createWebHistory } from 'vue-router'
import store from "../store/index";

// import useRefresh from '../utils/useRefresh.js'

//home
//import Home from '../views/Home.vue'
const Home = () => import('../views/Home.vue')

//partner
const FeaturedStores = () => import('../views/partners/FeaturedStores.vue')
const Partners = () => import('../views/partners/Partners.vue')
const LineCard = () => import('../views/partners/LineCard.vue')
const Webinars = () => import('../views/partners/Webinars.vue')
const Microsite = () => import('../views/partners/Microsite.vue')
//company
const About = () => import('../views/company/About.vue')
const Events = () => import('../views/company/Events.vue')
const EventDetails = () => import('../views/company/EventDetails.vue') 
//const EventFlyer = () => import('../views/company/EventFlyer.vue')
const Branches = () => import('../views/company/Branches.vue')
const Coronavirus = () => import('../views/company/Coronavirus.vue')
const CookieNotice = () => import('../views/company/CookieNotice.vue') 
const RecognitionsAndSpotlights = () => import('../views/company/RecognitionsAndSpotlights.vue') 
const Careers = () => import('../views/company/Careers.vue')
const WhyJoinMalabs = () => import('../views/company/WhyJoinMalabs.vue')
const WorkAtMalabs = () => import('../views/company/WorkAtMalabs.vue')
const JobOpenings = () => import('../views/company/JobOpenings.vue')
const CareerEvents = () => import('../views/company/CareerEvents.vue')
const PrivacyPolicy = () => import('../views/company/PrivacyPolicy.vue')
const Disclaimer = () => import('../views/company/Disclaimer.vue') 
const TermsAndConditions = () => import('../views/company/TermsAndConditions.vue')
const TermsAndConditionsSuppl = () => import('../views/company/TermsAndConditionsSuppl.vue')
const EmailNewsletters = () => import('../views/company/EmailNewsletters.vue')
const Unsubscribe = () => import('../views/company/Unsubscribe.vue')
const UkraineCrisis = () => import('../views/company/UkraineCrisis.vue')
const TechFairRegister = () => import('../views/company/TechFairRegister.vue')
//product
const Products = () => import('../views/products/Products.vue')
const ProductDetails = () => import('../views/products/ProductDetails.vue')
const Cart = () => import('../views/orders/Cart.vue')
const Shipping = () => import('../views/orders/shipping.vue')
const Quote = () => import('../views/orders/Quote.vue')
//account
const Login = () => import('../views/account/Login.vue')
const RegisterAccountLink = () => import('../views/account/RegisterAccountLink.vue')
const BecomeReseller = () => import('../views/account/BecomeReseller.vue')
const EmailVerify = () => import('../views/account/EmailVerify.vue')
const ResetPasswordLink = () => import('../views/account/ResetPasswordLink.vue')
const ResetPasswordAction = () => import('../views/account/ResetPasswordAction.vue')
const ResetPasswordHandler = () => import('../views/account/ResetPasswordHandler.vue')
const AccountOverview = () => import('../views/account/AccountOverview.vue')
const ChangePassword = () => import('../views/account/ChangePassword.vue')
const ShipmentTracking = () => import('../views/account/ShipmentTracking.vue')
const OrderDetails = () => import('../views/account/OrderDetails.vue')
const WebOrder = () => import('../views/account/WebOrder.vue')
const WebpoDetails = () => import('../views/account/WebpoDetails.vue')
const Newsletter = () => import('../views/account/Newsletter.vue')
const Datafeed = () => import('../views/account/Datafeed.vue')
const WebService = () => import('../views/account/WebService.vue')
const QuoteRequest = () => import('../views/account/QuoteRequest.vue')
const QuoteRequestDetails = () => import('../views/account/QuoteRequestDetails.vue')
const QuoteRequestPrint = () => import('../views/account/QuoteRequestPrint.vue')
//Server
const ServerSolutions = () => import('../views/server/ServerSolutions.vue')
const GpuServer = () => import('../views/server/GpuServer.vue')
const StorageServer = () => import('../views/server/StorageServer.vue')
// const XilinxSolutions = () => import('../views/server/XilinxSolutions.vue')
const DataCenter = () => import('../views/server/DataCenter.vue')
// const MiningSolutions = () => import('../views/server/MiningSolutions.vue')
const IndustrialSolutions = () => import('../views/server/IndustrialSolutions.vue')
const GamingSolutions = () => import('../views/server/GamingSolutions.vue')
const ServerPromotions = () => import('../views/server/ServerPromotions.vue')
const EdgeSolutions = () => import('../views/server/EdgeSolutions.vue') 
// const DinoEdge = () => import('../views/server/DinoEdge.vue')
const DigitalSolutions = () => import('../views/server/DigitalSolutions.vue')
//Support
const FAQ = () => import('../views/support/FAQ.vue')
const RMARequest = () => import('../views/support/RMARequest.vue')
const ContactUs = () => import('../views/support/ContactUs.vue')
//other
const NotFound = () => import('../views/NotFound.vue')
const BlockMe = () => import('../views/support/BlockMe.vue')
const UnblockMe = () => import('../views/support/UnblockMe.vue')
const BlockMeProcess = () => import('../views/support/BlockMeProcess.vue')
const BlockMeReport = () => import('../views/support/BlockMeReport.vue')
const EblastFlyer = () => import('../views/account/EblastFlyer.vue')
// //online application
const AppSignUp = () => import('../views/application/SignUp.vue')
const ReturnUser = () => import('../views/application/ReturnUser.vue')
const AppForm = () => import('../views/application/Form.vue')
// server configuration
const Config = () => import('../views/server/Config.vue')
const ConfigDetails = () => import('../views/server/ConfigDetails.vue') 
const ConfigQuote = () => import('../views/server/ConfigQuote.vue')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  //partners
  {
    path: '/partners/featured-partners',
    name: 'FeaturedStores',
    component: FeaturedStores,
  },
  {
    path: '/partners/featured-brands',
    name: 'Partners',
    component: Partners,
  },
  {
    path: '/partners/line-card',
    name: 'LineCard',
    component: LineCard,
  },
  {
    path: '/partners/webinars',
    name: 'Webinars',
    component: Webinars,
  },
  {
    path: '/partners/microsite/:brand',
    name: 'Microsite',
    component: Microsite,
    props: true
  },
  //server
  {
    path: '/solutions/server-solutions',
    name: 'ServerSolutions',
    component: ServerSolutions
  },
  {
    path: '/solutions/gpu-servers',
    name: 'GpuServer',
    component: GpuServer
  },
  {
    path: '/solutions/storage-servers',
    name: 'StorageServer',
    component: StorageServer
  },
  /*
  {
    path: '/solutions/xilinx',
    name: 'XilinxSolutions',
    component: XilinxSolutions
  },
  */
  {
    path: '/solutions/datacenter',
    name: 'DataCenter',
    component: DataCenter
  },
  /*
  {
    path: '/solutions/mining-solutions',
    name: 'MiningSolutions',
    component: MiningSolutions
  },
  */
  {
    path: '/solutions/industrial-solutions',
    name: 'IndustrialSolutions',
    component: IndustrialSolutions
  },
  {
    path: '/solutions/gaming-solutions',
    name: 'GamingSolutions',
    component: GamingSolutions
  },
  {
    path: '/solutions/server-promotions',
    name: 'ServerPromotions',
    component: ServerPromotions
  },
  {
    path: '/solutions/edge-solutions',
    name: 'EdgeSolutions',
    component: EdgeSolutions
  },
  /*{
    path: '/solutions/dinoedge',
    name: 'DinoEdge',
    component: DinoEdge
  },
  */
  {
    path: '/solutions/digital-solutions',
    name: 'DigitalSolutions',
    component: DigitalSolutions
  },
  {
    path: '/servers',
    redirect: '/contact-us/server'
  },
  {
    path: '/contact-us/:topic(\.*)',
    name: 'ContactUs',
    component: ContactUs,
    props: true
  },
  //company
  {
    path: '/company/overview',
    name: 'About',
    component: About,
  },
  {
    path: '/company/our-branches',
    name: 'Branches',
    component: Branches,
  },
  {
    path: '/company/careers',
    name: 'Careers',
    component: Careers,
  },
  {
    path: '/company/why-join-malabs',
    name: 'WhyJoinMalabs',
    component: WhyJoinMalabs,
  },
  {
    path: '/company/work-at-malabs',
    name: 'WorkAtMalabs',
    component: WorkAtMalabs,
  },
  {
    path: '/company/job-openings',
    name: 'JobOpenings',
    component: JobOpenings,
  },
  {
    path: '/company/career-events',
    name: 'CareerEvents',
    component: CareerEvents,
  },
  {
    path: '/company/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/company/notice',
    name: 'Coronavirus',
    component: Coronavirus,
  },
  {
    path: '/company/cookie-notice',
    name: 'CookieNotice',
    component: CookieNotice,
  },
  {
    path: '/company/recognitions-spotlights',
    name: 'RecognitionsAndSpotlights',
    component: RecognitionsAndSpotlights,
  },
  {
    path: '/company/events',
    name: 'Events',
    component: Events,
  },
  {
    path: '/company/events/:slug',
    name: 'EventDetails',
    component: EventDetails,
    props: true,
  },
  // {
  //   path: '/company/events/:flyer',
  //   name: 'EventFlyer',
  //   props: true,
  //   component: EventFlyer,
  // },
  {
    path: '/company/disclaimer',
    name: 'Disclaimer',
    component: Disclaimer,
  },
  {
    path: '/terms-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
  },
  {
    path: '/company/terms-conditions',
    redirect: {name: 'TermsAndConditions'}
  },
  {
    path: '/tc',
    redirect: {name: 'TermsAndConditions'}
  },
  {
    path: '/terms-conditions-suppl',
    name: 'TermsAndConditionsSuppl',
    component: TermsAndConditionsSuppl,
  },
  {
    path: '/company/newsletter',
    name: 'EmailNewsletters',
    component: EmailNewsletters,
  },
  {
    path: '/company/unsubscribe',
    name: 'Unsubscribe',
    component: Unsubscribe,
  },
  {
    path: '/company/ukraine-crisis',
    name: 'UkraineCrisis',
    component: UkraineCrisis,
  },
  {
    path: '/company/techfair/register/:slug',
    name: 'TechFairRegister',
    props: true,
    component: TechFairRegister,
  },
  //support
  {
    path: '/support/FAQ',
    name: 'FAQ',
    component: FAQ,
  },
  {
    path: '/support/RMA-request',
    name: 'RMARequest',
    component: RMARequest,
  },
  //account
  {
    path: '/account/account-overview',
    name: 'AccountOverview',
    component: AccountOverview,
    meta: { requiredAuth: true }
  },
  {
    path: '/account/change-password',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: { requiredAuth: true }
  },
  {
    path: '/account/order-status',
    name: 'ShipmentTracking',
    component: ShipmentTracking,
    meta: { requiredAuth: true }
  },
  {
    path: '/account/order-status/:invno',
    name: 'OrderDetails',
    component: OrderDetails,
    props: true,
    meta: { requiredAuth: true }
  },
  {
    path: '/account/web-order',
    name: 'WebOrder',
    component: WebOrder,
    meta: { requiredAuth: true }
  },
  {
    path: '/account/web-order/details/:webso',
    name: 'WebpoDetails',
    component: WebpoDetails,
    props: true,
    meta: { requiredAuth: true }
  },
  {
    path: '/account/email_management',
    name: 'Newsletter',
    component: Newsletter,
    meta: { requiredAuth: true }
  },
  {
    path: '/account/datafeed',
    name: 'Datafeed',
    component: Datafeed,
    meta: { requiredAuth: true }
  },
  {
    path: '/account/web-service',
    name: 'WebService',
    component: WebService,
    meta: { requiredAuth: true }
  },
  {
    path: '/account/quote-request',
    name: 'QuoteRequest',
    component: QuoteRequest,
    meta: { requiredAuth: true }
  },
  {
    path: '/account/quote-request/details/:id',
    name: 'QuoteRequestDetails',
    component: QuoteRequestDetails,
    props: true,
    meta: { requiredAuth: true }
  },
  {
    path: '/account/quote-request/print/:id',
    name: 'QuoteRequestPrint',
    component: QuoteRequestPrint,
    props: true,
    meta: { requiredAuth: true }
  },
  //products
  {
    path: '/products',
    name: 'Products',
    component: Products,
  },
  {
    path: '/products/details/:itemno?',
    name: 'ProductDetails',
    component: ProductDetails,
    props: true,
    meta: { requiredAuth: true }
  },
  //alias for old website url
  {
    path: '/product',
    redirect: {name: 'Products'}
  },
  {
    path: '/product/:itemno?',
    props: true,
    redirect: {name: 'ProductDetails'}
  },
  //po cart
  {
    path: '/orders/cart',
    name: 'Cart',
    component: Cart
  },
  {
    path: '/orders/shipping',
    name: 'Shipping',
    component: Shipping
  },
  {
    path: '/orders/quote',
    name: 'Quote',
    component: Quote
  },
  {
    path: '/eblast_subscribe',
    redirect: {name: 'Home'}
  },
  //login
  {
    path: '/login',
    name: 'Login',
    component: Login,
    props: route => ({query: route.query})

  },
  {
    path: '/registration',
    name: 'RegisterAccountLink',
    component: RegisterAccountLink,
  },
  {
    path: '/become-reseller',
    name: 'BecomeReseller',
    component: BecomeReseller,
  },
  {
    path: '/account/email-verify',
    name: 'EmailVerify',
    component: EmailVerify,
    props: route => ({query: route.query})
  },
  {
    path: '/reset-password',
    name: 'ResetPasswordLink',
    component: ResetPasswordLink
  },
  {
    path: '/password',
    redirect: {name: 'ResetPasswordLink'}
  },
  {
    path: '/account/password-reset/:uid/:token/',
    name: 'ResetPasswordHandler',
    component: ResetPasswordHandler,
    props: true
  },
  {
    path: '/resetPassword',
    name: 'ResetPasswordAction',
    component: ResetPasswordAction,
    props: route => ({query: route.query})
  },

  //block me
  {
    path: '/blockme',
    name: 'BlockMe',
    component: BlockMe
  },
  {
    path: '/unblockme',
    name: 'UnblockMe',
    component: UnblockMe
  },
  {
    path: '/blockme/process/:action/',
    name: 'BlockMeProcess',
    component: BlockMeProcess,
    props: true
  },
  {
    path: '/blockme/report',
    name: 'BlockMeReport',
    component: BlockMeReport,
    props: true
  },
  //other
  {
    path: '/eblast/view_flyer/',
    name: 'EblastFlyer',
    component: EblastFlyer,
    props: true
  },

  //catch all 404
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      requiresAuth: false
    }
  },

  // //online application
  {
    path: '/application/signup',
    name: 'AppSignUp',
    component: AppSignUp
  },
  {
    path: '/application/returnuser',
    name: 'ReturnUser',
    component: ReturnUser,
    props: true
  },
  {
    path: '/application/form',
    name: 'AppForm',
    component: AppForm
  },
  //configuration
  // {
  //   path: '/configuration/:category',
  //   name: 'Config',
  //   component: Config,
  //   props: true
  // },
  // {
  //   path: '/config/details',
  //   name: 'ConfigDetails',
  //   component: ConfigDetails,
  //   props: true,
  //   params: {
  //     components: "",
  //     config_payload: ""
  //   }
  // },
  // {
  //   path: '/config/quote',
  //   name: 'ConfigQuote',
  //   component: ConfigQuote,
  //   props: true,
  //   params: {
  //     item: "",
  //     components: "",
  //     config_payload: ""
  //   }
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  }
})


router.beforeEach((to,from, next) => {
  if(to.meta.requiredAuth){
      const auth = store.getters.isAuthenticated
      if(!auth){
        return next({name: 'Login', query: { from: to.fullPath } })
      }
  }
  return next();
});

export default router
